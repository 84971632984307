import { useRef } from 'react';
import { captureRef } from 'react-native-view-shot';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import { showToast } from '../lib/toast';

type UseChartCaptureHook = () => {
  viewShotRef: React.RefObject<any>;
  saveChartImage: () => Promise<string | null>;
  saveChartToMediaLibrary: () => Promise<void>;
};

const useChartCapture: UseChartCaptureHook = () => {
  const viewShotRef = useRef(null);

  const saveChartImage = async () => {
    if (viewShotRef.current) {
      try {
        const uri = await captureRef(viewShotRef.current, {
          format: 'jpg',
          quality: 1,
        });

        const fileName = `${FileSystem.documentDirectory}chart.jpg`;

        await FileSystem.copyAsync({
          from: uri,
          to: fileName,
        });

        console.log(`Chart image saved at ${fileName}`);
        return fileName;
      } catch (error) {
        console.error('Error saving chart image:', error);
      }
    }
    return null;
  };
  const saveChartToMediaLibrary = async () => {
    const uri = await saveChartImage();
    if (uri) {
      const { status } = await MediaLibrary.requestPermissionsAsync();
      if (status === 'granted') {
        const asset = await MediaLibrary.createAssetAsync(uri);
        await MediaLibrary.createAlbumAsync('Download', asset, false);

        showToast('Graf stažen do galerie', '', 'success', 3000);
      } else {
        console.log('Media Library access was not granted');
      }
    }
  };

  return { viewShotRef, saveChartImage, saveChartToMediaLibrary };
};

export default useChartCapture;
