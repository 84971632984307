import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Dialog, IconButton } from 'react-native-paper';
import { colors, theme } from '../../theme';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import { isWeb } from '../../lib/is-web';

interface Props {
  name: string | undefined;
  showData: boolean;
  setShowData: React.Dispatch<React.SetStateAction<boolean>>;
}

const RollOptions = ({ name, showData, setShowData }: Props) => {
  return (
    <FadeInFromTopAnimation delay={50}>
      <View style={[styles.header]}>
        <Dialog.Title style={{ fontSize: 15, margin: 0 }}>
          Podkategorie a rozšířující úkoly
        </Dialog.Title>
        <IconButton
          style={{
            backgroundColor: colors.white,
            marginLeft: 'auto',
          }}
          iconColor={theme.colors.primary}
          icon={showData ? 'chevron-up' : 'chevron-down'}
          onPress={() => setShowData((prev) => !prev)}
        />
      </View>
    </FadeInFromTopAnimation>
  );
};

export default RollOptions;

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: -6,
    paddingVertical: isWeb ? 10 : 2,
    paddingHorizontal: isWeb ? 16 : 6,
    marginRight: 5,
  },
});
