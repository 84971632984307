import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import IconWrapper from '../ui/IconWrapper';
import { colors } from '../../theme';
import { useRecoilValueLoadable } from 'recoil';
import { classStatsState } from '../../store';
import { ClassIDContext } from '../../lib/contexts';
import CircularProgress from '../ui/CircularProgress';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';

interface Props {
  length: number;
}

const ChildInfo = ({ length }: Props) => {
  const classId = React.useContext(ClassIDContext);
  const statsLoadable = useRecoilValueLoadable(classStatsState({ classId }));

  const { averageFillRate = 0 } =
    statsLoadable.state === 'hasValue' ? statsLoadable.contents : {};
  const percent = Math.round(averageFillRate * 1000) / 10;

  return (
    <BubbleScaleUpAnimation delay={500} duration={500}>
      <View style={styles.container}>
        <IconWrapper
          width={38}
          height={38}
          icon="account-group-outline"
          delay={700}
          children={undefined}
        />

        <Text style={styles.childText}>{length} dětí</Text>

        <CircularProgress
          delay={100}
          percent={percent}
          style={{
            position: 'absolute',
            top: 0,
            right: -25,
            backgroundColor: colors.lightOrange,
          }}
        />
      </View>
    </BubbleScaleUpAnimation>
  );
};

export default ChildInfo;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
    backgroundColor: colors.lightOrange,
    height: 85,
    width: 85,
    position: 'relative',

    borderRadius: 1000,
  },
  childText: {
    fontSize: 13,
    color: colors.text,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
