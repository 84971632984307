import React from 'react';
import { ScrollView, StyleSheet, ViewStyle } from 'react-native';

export const Scroller: React.FC<{
  style?: ViewStyle;
  children: React.ReactNode;
  keyboardShouldPersistTaps?: 'always' | 'never' | 'handled';
}> = ({ children, style }) => {
  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      style={styles.outer}
      contentContainerStyle={[styles.inner, style]}
    >
      {children}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  outer: {
    flex: 1,
  },
  inner: {
    padding: 10,
    flexGrow: 1,
  },
});
