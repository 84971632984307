import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  TextInput,
  Appbar,
  Headline,
  Subheading,
  Menu,
} from 'react-native-paper';
import { EditClassNoteProps } from '../lib/navigation';
import { useClassroomNoteOps } from '../actions';
import { useRecoilValue } from 'recoil';
import { allClassNotesState, classState } from '../store';
import { Components } from '../server';
import { useAssertOnline, useCurrentUser } from '../utils';
import { CustomHeader } from '../components/layout/CustomHeader';
import { Scroller } from '../components/ui/Scroller';
import { DatePicker } from '../components/ui/DatePicker';
import { Button } from '../components/ui/Button';
import { theme } from '../theme';
import { showToast } from '../lib/toast';
import FadeInFromLeftOrRight from '../components/animations/FadeInFromLeftOrRightAnimation';
import { BackgroundKeyboard } from '../components/ui/BackgroundKeyboard';

type ClassroomNoteData = Omit<Components.Schemas.ClassroomNote, 'id'> & {
  created_at: string;
};
const makeEmptyNote = ({
  classId,
  userId,
}: {
  classId: number;
  userId: number;
}): ClassroomNoteData => ({
  classroom: classId,
  note: '',
  created_by: userId,
  created_at: new Date().toISOString(),
});

export const EditClassNote = ({ route, navigation }: EditClassNoteProps) => {
  const ops = useClassroomNoteOps();
  const { noteId, classId } = route.params;
  const classroom = useRecoilValue(classState(classId));
  const initial = useRecoilValue(allClassNotesState)[noteId || -1];
  const assertOnline = useAssertOnline();
  const { id: userId } = useCurrentUser() || { id: 0 };

  const [data, setData] = React.useState(makeEmptyNote({ classId, userId }));
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    setData(
      initial
        ? { created_at: new Date().toISOString(), ...initial }
        : makeEmptyNote({ classId, userId }),
    );
  }, [classId, userId, initial]);

  const onSave = React.useCallback(async () => {
    if (assertOnline()) {
      if (noteId) {
        await ops.editClassroomNote(
          noteId,
          classId,
          data.created_at,
          data.note,
        );
        showToast('Úspěšně', 'Poznámka byla upravena', 'success', 3000);
      } else {
        await ops.addClassroomNote(classId, data.created_at, data.note);
        showToast('Úspěšně', 'Poznámka byla přidána', 'success', 3000);
      }

      navigation.goBack();
    }
  }, [assertOnline, navigation, noteId, ops, classId, data]);

  const onDelete = React.useCallback(async () => {
    if (noteId && assertOnline()) {
      await ops.deleteClassroomNote(noteId, classId);
      showToast('Úspěšně', 'Poznámka byla smazána', 'success', 3000);
      navigation.goBack();
    }
  }, [assertOnline, ops, navigation, noteId, classId]);

  return (
    <>
      <CustomHeader
        title={<Subheading style={[styles.white]}>Hodnocení třídy</Subheading>}
        menu={
          !noteId ? null : (
            <View style={styles.menu}>
              <Menu
                visible={visible}
                onDismiss={() => setVisible(false)}
                style={{ marginTop: 50 }}
                anchor={
                  <Appbar.Action
                    icon={visible ? 'close' : 'menu'}
                    color={theme.colors.primary}
                    containerColor="transparent"
                    onPress={() => setVisible(true)}
                    rippleColor={theme.colors.primary}
                  />
                }
              >
                <Menu.Item
                  onPress={() => {
                    setVisible(false);
                    onDelete();
                  }}
                  contentStyle={{ marginLeft: 0 }}
                  title="Smazat poznámku"
                  leadingIcon={'delete'}
                  style={{ marginLeft: 0 }}
                />
              </Menu>
            </View>
          )
        }
      />

      <BackgroundKeyboard isHeight>
        <Scroller style={styles.container}>
          <FadeInFromLeftOrRight delay={100} direction={'left'}>
            <Headline style={{ color: theme.colors.primary, fontSize: 20 }}>
              {classroom?.label}
            </Headline>
          </FadeInFromLeftOrRight>
          <View>
            <DatePicker
              date={new Date(data.created_at)}
              setDate={(date) => {
                setData((x) => ({
                  ...x,
                  created_at: (date || new Date()).toISOString(),
                }));
              }}
            />
            <FadeInFromLeftOrRight delay={300} direction={'left'}>
              <TextInput
                mode="outlined"
                label="Poznámka"
                returnKeyType="done"
                value={data.note}
                onChangeText={(note) => setData((x) => ({ ...x, note }))}
                autoComplete="off"
                multiline={true}
                numberOfLines={4}
                style={[styles.whiteBg, { height: 110 }]}
                autoFocus
              />
            </FadeInFromLeftOrRight>
          </View>

          <View style={{ minHeight: 10, flexGrow: 1 }} />
          <Button mode="contained" onPress={onSave}>
            Uložit
          </Button>
        </Scroller>
      </BackgroundKeyboard>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 10,
  },
  menu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 10000,
    marginRight: 7,
    marginBottom: 7,
  },

  white: {
    color: 'white',
  },
  whiteBg: {
    backgroundColor: 'white',
  },
});
