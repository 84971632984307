import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { FAB } from 'react-native-paper';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { RootStackParamList } from '../../lib/navigation';
import { useAuth } from '../../hooks/use-auth';
import { alertCross } from '../../utils';
import { useMultiFABScroll } from './MultiFABContext';
import { colors } from '../../theme';
import { isWeb } from '../../lib/is-web';

export interface MultiFABProps {
  multi?: boolean;
  initial: RootStackParamList['CreateAssessment'];
}

export const MultiFAB: React.FC<{
  visible?: boolean;
  tabs?: boolean;
}> = ({ visible, tabs }) => {
  const [open, setOpen] = React.useState(false);
  const {
    props: { initial, multi },
    showButton,
    setStatus,
  } = useMultiFABScroll();
  const navigation = useNavigation() as any;
  const insets = useSafeAreaInsets();
  const isConnected = true;
  const { demo } = useAuth();

  const createNote = React.useCallback(() => {
    if (!isConnected && !demo) {
      alertCross(
        'Jste offline',
        'Pro přidání hodnocení nebo poznámky se nejprve připojte k internetu',
      );
      return;
    }
    if (initial?.childIds?.length) {
      navigation.navigate('EditChildNote', { childId: initial.childIds![0]! });
    } else {
      navigation.navigate('EditClassroomNote', { classId: initial.classId });
    }
  }, [initial, navigation, isConnected, demo]);

  const createAssessment = React.useCallback(() => {
    if (!isConnected && !demo) {
      alertCross(
        'Jste offline',
        'Pro přidání hodnocení nebo poznámky se nejprve připojte k internetu',
      );
      return;
    }
    navigation.navigate('CreateAssessment', initial);
    setStatus({ initial: { classId: initial.classId } });
  }, [initial, navigation, isConnected, demo, setStatus]);

  return (
    <FAB.Group
      visible={visible ? showButton : false}
      open={open}
      icon={multi ? 'order-bool-ascending-variant' : open ? 'close' : 'plus'}
      color="white"
      style={
        {
          position: isWeb ? 'fixed' : 'absolute',
          paddingBottom: insets.bottom + (tabs ? 50 : 0),
          paddingRight: insets.right + (isWeb ? 10 : 0),
        } as any
      }
      fabStyle={{
        backgroundColor: !isConnected
          ? colors.grey
          : !multi
            ? colors.blue
            : colors.green,
      }}
      actions={[
        { icon: 'note-plus', label: 'Přidat poznámku', onPress: createNote },
        {
          icon: 'order-bool-ascending-variant',
          label: 'Vyplnit úkol',
          onPress: createAssessment,
        },
      ]}
      onStateChange={({ open }) => {
        if (!multi) {
          setOpen(open);
        }
      }}
      onPress={multi ? createAssessment : () => setOpen((x) => !x)}
    />
  );
};
