import { useIsFocused } from '@react-navigation/native';
import React from 'react';
import { FlatList, View, StyleSheet } from 'react-native';
import { Caption } from 'react-native-paper';
import { ClassIDContext } from '../../lib/contexts';
import { ClassNotesProps } from '../../lib/navigation';
import { useRecoilValue } from 'recoil';
import {
  ChildType,
  classChildrenState,
  classNotesState,
  classState,
  usersState,
} from '../../store';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { Components } from '../../server';
import { format } from 'date-fns';
import { RefreshUserData } from '../RefreshUserData';
import { Background } from '../ui/Background';
import { colors } from '../../theme';
import NoResults from '../ui/NoResults';
import { removeDiacritics } from '../../lib/remove-diacritics';
import CustomSearchBar from '../ui/CustomSearchBar';
import Notes from '../Notes';
import { Button } from '../ui/Button';
import { ChildListItem } from '../child/ChildListItem';
import { BubbleScaleUpAnimation } from '../animations/BubbleScaleUpAnimation';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';

type noteEnum = 'class' | 'child';

export const ClassNotes = ({ navigation }: ClassNotesProps) => {
  const isFocused = useIsFocused();
  const classId = React.useContext(ClassIDContext);
  const children = useRecoilValue(classChildrenState(classId));
  const classroom = useRecoilValue(classState(classId));
  const notes = useRecoilValue(classNotesState(classId));
  const users = useRecoilValue(usersState);
  const { setStatus } = useMultiFABScroll();
  const [searchQuery, setSearchQuery] = React.useState('');
  const [activeNote, setActiveNote] = React.useState<noteEnum>('class');
  const [selected, setSelected] = React.useState<number[]>([]);

  const onChangeSearch = (query: string) => setSearchQuery(query);
  const searchRef = React.useRef<any | undefined>(null);

  const filteredNotes = notes.filter((note) => {
    const noteSearch = removeDiacritics(note.note.toLowerCase());
    const dateSearch = format(new Date(note.created_at!), 'd. M. yyyy');
    const user = users[note.created_by || 0];
    const userSearch = user?.first_name
      ? removeDiacritics(`${user?.first_name} ${user?.last_name}`)
      : user?.email;

    return (
      noteSearch.includes(searchQuery.toLowerCase()) ||
      dateSearch.includes(searchQuery.toLowerCase()) ||
      userSearch?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const { isDesktop, desktopContainerStyle } = useDesktopStyles();

  const filteredChildren = children.filter((child) => {
    const childSearch = removeDiacritics(child.first_name + child.last_name);
    return childSearch.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const handleScale = (note: noteEnum) => {
    if (activeNote === note) {
      return 1;
    }
    return 0.95;
  };

  React.useEffect(() => {
    if (isFocused) {
      setStatus({ initial: { classId } });
    }
  }, [isFocused, setStatus, classId]);

  const renderItemClassNotes = ({
    item,
    index,
  }: {
    item: Components.Schemas.ClassroomNote;
    index: number;
  }) => {
    const user = users[item.created_by || 0];
    return (
      <Notes
        item={item}
        index={index}
        navigate={() => {
          navigation.navigate('EditClassroomNote', {
            classId,
            noteId: item.id,
          });
        }}
        user={user}
      />
    );
  };
  const onPressChild = (childId: number) => {
    navigation.navigate('Child', { childId, screen: 'ChildNotes' });
  };

  const renderItemChild = ({
    item,
    index,
  }: {
    item: ChildType;
    index: number;
  }) => (
    <>
      <ChildListItem
        selected={selected.includes(item.id)}
        {...{ item }}
        hideCheckbox
        onPress={onPressChild}
      />
    </>
  );

  return (
    <Background>
      {notes.length <= 0 && (
        <View
          style={{ margin: 30, justifyContent: 'center', alignItems: 'center' }}
        >
          <Caption>Přidejte poznámky kliknutím na &quot;+&quot;</Caption>
        </View>
      )}
      <View style={[styles.container, isDesktop && desktopContainerStyle]}>
        <View style={styles.topContainer}>
          <CustomSearchBar
            searchQuery={searchQuery}
            onChange={onChangeSearch}
            ref={searchRef}
          />

          <View style={styles.viewContainer}>
            <BubbleScaleUpAnimation delay={100}>
              <Button
                onPress={() => setActiveNote('class')}
                mode={activeNote === 'class' ? 'contained' : 'outlined'}
                style={{ transform: [{ scale: handleScale('class') }] }}
              >
                Třída
              </Button>
            </BubbleScaleUpAnimation>
            <BubbleScaleUpAnimation delay={400}>
              <Button
                onPress={() => setActiveNote('child')}
                mode={activeNote === 'child' ? 'contained' : 'outlined'}
                style={{ transform: [{ scale: handleScale('child') }] }}
              >
                Děti
              </Button>
            </BubbleScaleUpAnimation>
          </View>
        </View>

        {searchQuery &&
          ((activeNote === 'class' && !filteredNotes.length) ||
            (activeNote === 'child' && !filteredChildren.length)) && (
            <NoResults />
          )}

        {activeNote === 'class' && (
          <FlatList
            contentContainerStyle={{ padding: 8 }}
            data={filteredNotes}
            renderItem={renderItemClassNotes}
            keyExtractor={({ id }) => id.toString() + 'class'}
            refreshControl={<RefreshUserData />}
            ListFooterComponent={<View style={{ height: 100 }} />}
          />
        )}

        {activeNote === 'child' && (
          <FlatList
            style={{ padding: 8 }}
            initialNumToRender={40}
            data={filteredChildren}
            contentContainerStyle={{ paddingBottom: 20 }}
            refreshControl={<RefreshUserData />}
            keyExtractor={({ id }) => id.toString() + 'child'}
            numColumns={2}
            renderItem={renderItemChild}
          />
        )}
      </View>
    </Background>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  topContainer: {
    width: '100%',
    paddingHorizontal: 8,
    paddingBottom: 8,
  },
  card: {
    marginVertical: 3,
    padding: 10,
    borderRadius: 10,
    borderColor: colors.darkRed,
    borderWidth: 1,
  },

  viewContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  note: {
    maxWidth: '90%',
  },
});
