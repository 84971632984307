import React from 'react';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, StyleSheet, View } from 'react-native';
import { Caption, Headline, Portal, Subheading } from 'react-native-paper';
import { ClassTaskProps } from '../lib/navigation';
import { useRecoilValue } from 'recoil';
import {
  assessmentTypeState,
  categoriesState,
  childrenState,
  classAssessmentsByTaskState,
  classState,
  subcategoriesState,
  taskState,
  usersState,
} from '../store';
import { useMultiFABScroll } from '../components/multifab/MultiFABContext';
import { MultiFAB } from '../components/multifab/MultiFAB';
import { Components } from '../server';
import { format } from 'date-fns';
import { RefreshUserData } from '../components/RefreshUserData';
import { HeaderMenu } from '../components/layout/HeaderMenu';
import { CustomHeader } from '../components/layout/CustomHeader';
import { icons } from '../components/icons';
import AssessmentCard from '../components/assessments/AssessmentCard';
import { theme } from '../theme';
import { removeDiacritics } from '../lib/remove-diacritics';
import NoResults from '../components/ui/NoResults';
import CustomSearchBar from '../components/ui/CustomSearchBar';
import { BackgroundKeyboard } from '../components/ui/BackgroundKeyboard';

export const ClassTaskScreen = React.memo(function ClassTaskScreen({
  route,
  navigation,
}: ClassTaskProps) {
  const { classId, taskId } = route.params;
  const task = useRecoilValue(taskState(taskId))!;
  const categories = useRecoilValue(categoriesState);
  const subcategories = useRecoilValue(subcategoriesState);
  const classroom = useRecoilValue(classState(classId))!;
  const children = useRecoilValue(childrenState);
  const users = useRecoilValue(usersState);

  const assessments = useRecoilValue(classAssessmentsByTaskState(classId));
  const assessmentType = useRecoilValue(
    assessmentTypeState(task?.assessment_type || 0),
  );
  const isFocused = useIsFocused();
  const { setStatus } = useMultiFABScroll();

  const searchRef = React.useRef<any | undefined>(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const onChangeSearch = (query: string) => setSearchQuery(query);

  const CategoryIcon =
    icons[
      categories[subcategories[task?.subcategory || 0]?.parent_category || 0]
        ?.label || ''
    ];

  React.useEffect(() => {
    if (isFocused) {
      setStatus({ initial: { classId, taskIds: [task?.id] } });
    }
  }, [isFocused, setStatus, classId, task?.id]);

  const filteredAssessments = React.useMemo(() => {
    if (!searchQuery) return assessments[task?.id];
    const query = removeDiacritics(searchQuery.toLowerCase());
    return assessments[task?.id]?.filter((x) => {
      const child = children[x.child];
      const user = users[x.assessed_by || 0];
      const childName = removeDiacritics(
        `${child?.first_name} ${child?.last_name}`.toLowerCase(),
      );
      const note = removeDiacritics(x?.note?.toLowerCase() || '');

      const userName = removeDiacritics(
        `${user?.first_name} ${user?.last_name}`.toLowerCase(),
      );
      const date = removeDiacritics(
        format(new Date(x.date_of_assessment), 'dd.MM.yyyy'),
      );

      return (
        childName.includes(query) ||
        userName.includes(query) ||
        date.includes(query) ||
        note.includes(query)
      );
    });
  }, [searchQuery, assessments, task?.id, children, users]);

  const renderItem = ({
    item,
    index,
  }: {
    item: Components.Schemas.Assessment;
    index: number;
  }) => {
    const user = users[item.assessed_by || 0];
    const child = children[item.child];
    const option = assessmentType?.options?.find((x) => x.id === item.option);
    const isMaleGender = child?.gender === 'M';
    return (
      <AssessmentCard
        direction={index % 2 === 0 ? 'left' : 'right'}
        item={item}
        user={user}
        child={child}
        task={task}
        onPressEdit={() =>
          navigation.navigate('EditAssessment', { assessmentId: item?.id ?? 0 })
        }
        onPressChild={() =>
          navigation.navigate('Child', { childId: child?.id ?? 0 })
        }
        option={option}
        Icon={CategoryIcon}
        isMaleGender={isMaleGender}
      />
    );
  };

  return (
    <>
      <CustomHeader
        title={<Subheading style={styles.white}>Hodnocení třídy</Subheading>}
        menu={<HeaderMenu navigation={navigation} />}
      />
      <BackgroundKeyboard isHeight={true}>
        <CustomSearchBar
          searchQuery={searchQuery}
          onChange={onChangeSearch}
          ref={searchRef}
        />

        {searchQuery && !filteredAssessments?.length && <NoResults />}

        <FlatList
          contentContainerStyle={{ padding: 10 }}
          refreshControl={<RefreshUserData />}
          ListHeaderComponent={
            <>
              <Headline style={styles.classroom}>{classroom?.label}</Headline>
              {!assessments[task?.id]?.length && (
                <View style={styles.emptyState}>
                  <Caption>Přidejte hodnocení kliknutím na {'"+"'}</Caption>
                </View>
              )}
            </>
          }
          data={filteredAssessments}
          keyExtractor={({ id }) => id.toString()}
          renderItem={renderItem}
          ListFooterComponent={<View style={{ height: 120 }} />}
        />

        <Portal>
          <MultiFAB visible={isFocused} />
        </Portal>
      </BackgroundKeyboard>
    </>
  );
});

const styles = StyleSheet.create({
  classroom: {
    marginBottom: 4,
    padding: 6,
    color: theme.colors.primary,
    fontWeight: 'bold',
    fontSize: 20,
  },
  emptyState: {
    margin: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: 1,
  },
  white: {
    color: 'white',
  },
  icon: {
    width: 40,
    height: 40,
    alignSelf: 'flex-end',
  },
});
