import React from 'react';
import { StyleSheet, View } from 'react-native';
import { TextInput, Appbar, Subheading, Menu } from 'react-native-paper';
import { EditChildNoteProps } from '../lib/navigation';
import { useChildNoteOps } from '../actions';
import { useRecoilValue } from 'recoil';
import { allChildNotesState, childState } from '../store';
import { Components } from '../server';
import { useAssertOnline, useCurrentUser } from '../utils';
import { CustomHeader } from '../components/layout/CustomHeader';
import { DatePicker } from '../components/ui/DatePicker';
import { Button } from '../components/ui/Button';
import { Scroller } from '../components/ui/Scroller';
import { theme } from '../theme';
import { showToast } from '../lib/toast';
import ChildTypeGender from '../components/child/ChildTypeGender';
import { BackgroundKeyboard } from '../components/ui/BackgroundKeyboard';
import FadeInFromLeftOrRight from '../components/animations/FadeInFromLeftOrRightAnimation';
import { FadeInFromTopAnimation } from '../components/animations/FadeInFromTopAnimation';

type ChildNoteData = Omit<Components.Schemas.ChildNote, 'id'> & {
  created_at: string;
};
const makeEmptyNote = ({
  childId,
  userId,
}: {
  childId: number;
  userId: number;
}): ChildNoteData => ({
  child: childId,
  note: '',
  created_by: userId,
  created_at: new Date().toISOString(),
});

export const EditChildNote = ({ route, navigation }: EditChildNoteProps) => {
  const ops = useChildNoteOps();
  const { noteId, childId } = route.params;
  const child = useRecoilValue(childState(childId));
  const initial = useRecoilValue(allChildNotesState)[noteId || -1];
  const assertOnline = useAssertOnline();
  const { id: userId } = useCurrentUser() || { id: 0 };
  const [visible, setVisible] = React.useState(false);

  const [data, setData] = React.useState(makeEmptyNote({ childId, userId }));
  const isMaleGender = child?.gender === 'M';
  React.useEffect(() => {
    setData(
      initial
        ? { created_at: new Date().toISOString(), ...initial }
        : makeEmptyNote({ childId, userId }),
    );
  }, [initial, childId, userId]);

  const onSave = React.useCallback(async () => {
    if (assertOnline()) {
      if (noteId) {
        await ops.editChildNote(noteId, childId, data.created_at, data.note);
        showToast('Úspěšně', 'Poznámka byla upravena', 'success', 3000);
      } else {
        await ops.addChildNote(childId, data.created_at, data.note);
        showToast('Úspěšně', 'Poznámka byla přidána', 'success', 3000);
      }
      navigation.goBack();
    }
  }, [assertOnline, noteId, childId, data, ops, navigation]);

  const onDelete = React.useCallback(async () => {
    if (noteId && assertOnline()) {
      await ops.deleteChildNote(noteId, childId);
      showToast('Úspěšně', 'Poznámka byla smazána', 'success', 3000);
      navigation.goBack();
    }
  }, [assertOnline, noteId, navigation, ops, childId]);

  return (
    <>
      <CustomHeader
        title={<Subheading style={styles.white}>Poznámka k dítěti</Subheading>}
        menu={
          !noteId ? null : (
            <View style={styles.menu}>
              <Menu
                visible={visible}
                onDismiss={() => setVisible(false)}
                style={{ marginTop: 50 }}
                anchor={
                  <Appbar.Action
                    icon={visible ? 'close' : 'menu'}
                    color={theme.colors.primary}
                    containerColor="transparent"
                    onPress={() => setVisible(true)}
                    rippleColor={theme.colors.primary}
                  />
                }
              >
                <Menu.Item
                  onPress={() => {
                    setVisible(false);
                    onDelete();
                  }}
                  contentStyle={{ marginLeft: 0 }}
                  title="Smazat"
                  leadingIcon={'delete'}
                  style={{ marginLeft: 0 }}
                />
              </Menu>
            </View>
          )
        }
      />

      <BackgroundKeyboard isHeight>
        <Scroller>
          <FadeInFromTopAnimation duration={100} delay={0}>
            <View style={{ height: 10 }} />
            <ChildTypeGender
              child={child}
              isGender={isMaleGender}
              fontSize={18}
            />
            <View style={{ height: 10 }} />
          </FadeInFromTopAnimation>

          <DatePicker
            date={new Date(data.created_at)}
            setDate={(date) => {
              setData((x) => ({
                ...x,
                created_at: (date || new Date()).toISOString(),
              }));
            }}
          />
          <FadeInFromLeftOrRight delay={0} direction={'left'}>
            <TextInput
              mode="outlined"
              label="Poznámka"
              returnKeyType="done"
              value={data.note}
              onChangeText={(note) => setData((x) => ({ ...x, note }))}
              autoComplete="off"
              multiline={true}
              numberOfLines={4}
              style={styles.whiteBg}
              autoFocus
            />
          </FadeInFromLeftOrRight>
          <View style={{ minHeight: 10, flexGrow: 1 }} />
          <Button mode="contained" onPress={onSave}>
            Uložit
          </Button>
        </Scroller>
      </BackgroundKeyboard>
    </>
  );
};

const styles = StyleSheet.create({
  menu: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 10000,
    marginRight: 7,
    marginBottom: 7,
  },
  white: {
    color: 'white',
  },
  whiteBg: {
    backgroundColor: 'white',
  },
});
