import { registerRootComponent } from 'expo';
import * as Sentry from 'sentry-expo';

import App from './App';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

Sentry.init({
  dsn: IS_PRODUCTION
    ? 'https://70ee06ed451d47159313edb47a945ad7@app.glitchtip.com/3419'
    : 'https://440c53cb5b614d7c8377d7ef70e70df5@app.glitchtip.com/6181',
  enableInExpoDevelopment: true,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
