import React from 'react';
import { Text, View, StyleSheet, Easing } from 'react-native';
import { colors } from '../../theme';
import {
  colorBasedOnPercent,
  reverseColorBasedOnPercent,
} from '../../lib/color-based-on-percent';
import { AnimatedCircularProgress } from 'react-native-circular-progress';

interface Props {
  style?: any;
  percent: number;
  bgColor?: string;
  textColor?: string;
  duration?: number;
  size?: number;
  reverse?: boolean;
  delay?: number;
}

const CircularProgress = ({
  style,
  percent,
  duration,
  bgColor,
  textColor,
  size,
  delay,
  reverse,
}: Props) => {
  const ref = React.useRef(null);

  return (
    <View style={[styles.container, style]}>
      <AnimatedCircularProgress
        prefill={0}
        duration={duration || 1000}
        delay={delay || 0}
        easing={Easing.linear}
        ref={ref}
        size={size || 52}
        width={5}
        fill={percent}
        tintColor={
          reverse
            ? reverseColorBasedOnPercent(percent) || colors.green
            : colorBasedOnPercent(percent)
        }
        backgroundColor={bgColor || colors.grey}
        rotation={0}
      />

      <Text style={[styles.display, { color: textColor || colors.text }]}>
        {percent}%
      </Text>
    </View>
  );
};

export default CircularProgress;

const styles = StyleSheet.create({
  container: {
    width: 46,
    height: 46,
    borderWidth: 2,
    borderRadius: 100,
    borderColor: colors.grey,
    justifyContent: 'center',
    alignItems: 'center',
  },

  display: {
    position: 'absolute',
    fontSize: 10.5,
    fontWeight: 'bold',
  },
});
